<template>
    <div class="container mt-5">
      <h3 class="text-info">Detalles del Juvenil</h3>
      <template v-if="!isLoading">
        <div class="row d-flex">
          
          <div class="col-8">
            <div class="row">
                <div class="col-1 text-primary">Trabajador:</div>
                <div class="col-8 mx-4">
                  <a class="font-weight-bold" :href="`/trabajadores/${infant.customer.id}`">
                    {{ infant.customer?.first_name }} {{ infant.customer?.last_name  }}
                  </a>
                </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row d-flex">
              <template v-if="readOnly">
                <div class="col-6">
                  <button @click="readOnly = false" class="btn btn-info">Editar Registro</button>
                </div>
                <div class="col-6">
                  <button class="btn btn-primary" @click="printPDF">
                    <template v-if="downloading">
                      Imprimiendo ...
                    </template>
                    <template v-else>
                      Imprimir Kardex
                    </template>
                    
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="col-6">
                  <button @click="readOnly = true" class="btn btn-secondary">Descartar</button>
                </div>
                <div class="col-6">
                  <button @click.stop="update" class="btn btn-success">Guardar</button>
                </div>
              </template>
            </div>
          </div>
          
          
        </div>
        <div class="row d-flex">
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Nombre</label>
              <input :disabled="readOnly" type="text" class="form-control" v-model="infant.first_name">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Apellido</label>
              <input type="text" class="form-control" v-model="infant.last_name" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Edad</label>
              <input type="text" class="form-control" v-model="infant.age" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">E-mail</label>
              <input type="text" class="form-control" v-model="infant.email" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Equipo</label>
              <select v-if="!readOnly" class="form-control" v-model="selectedTeam">
                <option v-for="(option, index) in teams" :value="option" :key="index"><p>{{ option.name }}</p></option>
              </select>
              <input  v-if="readOnly" type="text" class="form-control" v-model="selectedTeam.name" :disabled="true">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Nivel</label>
              <input type="text" class="form-control" v-model="selectedTeam.level" :disabled="true">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex">
            <div class="col-6">
              <div class="row">
                <h6 class="text-primary">Talleres de ciencia</h6>
                  <template v-if="!readOnly">
                    <div class="row">
                      <div>
                        <label class="form-label text-primary">Semana 1:</label>
                        <select  class="form-control" v-model="selectedScienceOne">
                          <option v-for="(workshopScience, index) in allWorkshopsScience" :value="workshopScience" :key="index"><p>{{ workshopScience.name }}</p></option>
                        </select>
                      </div>
                      <div>
                        <label class="form-label text-primary">Semana 2:</label>
                        <select  class="form-control" v-model="selectedScienceTwo">
                          <option v-for="(workshopScience, index) in allWorkshopsScience" :value="workshopScience" :key="index"><p>{{ workshopScience.name }}</p></option>
                        </select>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row">
                      <div class="d-flex">
                        <p>Semana 1:</p>
                        <p>{{ selectedScienceOne?.name }}</p>
                      </div>
                      <div class="d-flex">
                        <p>Semana 2:</p>
                        <p>{{ selectedScienceTwo?.name }}</p>
                      </div>
                    </div>
                    
                  </template>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <h6 class="text-primary">Talleres de Artes y Humanidades</h6>
                <template v-if="!readOnly">
                    <div class="row">
                      <div>
                        <label class="form-label text-primary">Semana 1:</label>
                        <select  class="form-control" v-model="selectedArtOne">
                          <option v-for="(workshopArt, index) in allWorkshopsArt" :value="workshopArt" :key="index"><p>{{ workshopArt.name }}</p></option>
                        </select>
                      </div>
                      <div>
                        <label class="form-label text-primary">Semana 2:</label>
                        <select  class="form-control" v-model="selectedArtTwo">
                          <option v-for="(workshopArt, index) in allWorkshopsArt" :value="workshopArt" :key="index"><p>{{ workshopArt.name }}</p></option>
                        </select>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row">
                      <div class="d-flex">
                        <p>Semana 1:</p>
                        <p>{{ selectedArtOne?.name }}</p>
                      </div>
                      <div class="d-flex">
                        <p>Semana 2:</p>
                        <p>{{ selectedArtTwo?.name }}</p>
                      </div>
                    </div>
                    
                  </template>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h6 class="text-primary">Horario primera semana</h6>
          <table class="table table-bordered schedule">
            <thead>
              <tr>
                <th>Horario</th>
                <th>Actividad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>9:00 a 10:45</td>
                <td>{{ resolveWorkshop(1,0) }}</td>
              </tr>
              <tr>
                <td>11:30 a 12:45</td>
                <td>{{ resolveWorkshop(2,0) }}</td>
              </tr>
              <tr>
                <td>12:45 a 14:00</td>
                <td>{{ resolveWorkshop(3,0) }}</td>
              </tr>
            </tbody>
          </table>

          <h6 class="text-primary">Horario segunda semana</h6>
          <table class="table table-bordered schedule">
            <thead>
              <tr>
                <th>Horario</th>
                <th>Actividad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>9:00 a 10:45</td>
                <td>{{ resolveWorkshop(1,1) }}</td>
              </tr>
              <tr>
                <td>11:30 a 12:45</td>
                <td>{{ resolveWorkshop(2,1) }}</td>
              </tr>
              <tr>
                <td>12:45 a 14:00</td>
                <td>{{ resolveWorkshop(3,1) }}</td>
              </tr>
            </tbody>
          </table>

          <h6 class="text-primary">Ingresos</h6>
          <table class="table table-bordered schedule">
            <thead>
              <tr>
                <th>Dia y hora</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entrance,index) in infant.entrances" :key="index">
                <td>{{ formatDate(entrance.created_at) }}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <template v-else>
        <p>Cargando datos...</p>
      </template>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import moment from "moment"
  import es from "moment/dist/locale/es";
  moment.updateLocale("es", es);
  const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
  export default {
    name: 'InfantDetails',
    
    data() {
      return {
        infant: null,
        isLoading: false,
        readOnly: true,
        teams: [],
        allWorkshopsArt: [],
        allWorkshopsScience: [],
        selectedTeam: null,
        selectedScienceOne: null,
        selectedScienceTwo: null,
        selectedArtOne: null,
        selectedArtTwo: null,
        downloading: false
      };
    },
    created() {
      this.fetchInfantDetails();
    },
    computed:{
      scienceWorkshops(){
        const result = this.infant.workshops.filter((workshop) => workshop.type === "Ciencias")
        return result
      },
      artWorkshops(){
        const result = this.infant.workshops.filter((workshop) => workshop.type === "Arte y Humanidades")
        return result
      }
    },   
    methods: {
      async fetchInfantDetails() {
        this.isLoading = true;
        const id = this.$route.params.id;
        try {
          const response = await axios.get(`${PUBLIC_API_URL}/youths/${id}`);
          console.log('response: ',response.data)
          const responseTeams = await axios.get(`${PUBLIC_API_URL}/teams-youths`);
          this.infant = response.data;
          this.teams = responseTeams.data;

          const responseList = await axios.get(`${PUBLIC_API_URL}/workshops/list`);
          this.allWorkshopsArt = responseList.data.art;
          this.allWorkshopsScience = responseList.data.science;
          const payload = { 
            ...this.infant.teams[0],
            "pivot": {
              "youth_id": this.infant.id,
              "team_id": this.infant.teams[0].id
            }
          }
          this.selectedTeam = payload;
          const workshopsObj = this.infant.workshops;
          

          workshopsObj.forEach(element => {
              if(element.type === 'Ciencias'){
                switch (element.pivot.week) {
                  case 1:
                    this.selectedScienceOne = element
                    break;
                  case 2:
                    this.selectedScienceTwo = element
                    break;
                  default:
                    break;
                }
              }else{
                switch (element.pivot.week) {
                  case 1:
                    this.selectedArtOne = element
                    break;
                  case 2:
                    this.selectedArtTwo = element
                    break;
                  default:
                    break;
                }
              }
          });
        } catch (error) {
          this.$swal({
            title: "Ocurrio un error!",
            text: "No pudimos obtener los datos del juvenil!",
            icon: "error"
          });
        }
        
        this.isLoading = false;
      },
      async update(){
        
        this.isLoading = true;
        try {
          const workshopsPayload = [];

          workshopsPayload.push({
            ...this.selectedScienceOne,
            week:1 
          });
          workshopsPayload.push({
            ...this.selectedScienceTwo,
            week: 2 
          });
          workshopsPayload.push({
            ...this.selectedArtOne,
            week:1 
          });
          workshopsPayload.push({
            ...this.selectedArtTwo,
            week:2 
          });
          const payload = {
            ...this.infant,
            'team_id': this.selectedTeam.id,
            'workshops': workshopsPayload
          }
          const response = await axios.put(`${PUBLIC_API_URL}/youths/${this.infant.id}`, payload);
          console.log('response: ',response.data)
          this.fetchInfantDetails()
          this.readOnly = true
          this.$swal({
            title: "Actualizado con Exito!",
            text: "Los datos del juvenil fueron actualizados!",
            icon: "success"
          });
        } catch (error) {
          this.$swal({
            title: "Ocurrio un error!",
            text: "No pudimos actualizar los datos del juvenil!",
            icon: "error"
          });
        }
      },
      resolveWorkshop(step, weekIndex) {
        const userLevel = this.selectedTeam.level;
        let workshop = '';

        const getWorkshop = (level, weekIndex) => {
          switch (level) {
            case 1:
              return this.scienceWorkshops[weekIndex]?.name;
            case 2:
              return this.artWorkshops[weekIndex]?.name;
            case 3:
              return `Desierto de ${this.infant.teams[0]?.name}`;
          }
        };
        if (step === 1) {
          switch (userLevel) {
            case "1":
              workshop = getWorkshop(1, weekIndex);
              break;
            case "2":
              workshop = getWorkshop(2, weekIndex);
              break;
            case "3":
              workshop = getWorkshop(3, weekIndex);
              break;
          }
        } else if (step === 2) {
            switch (userLevel) {
              case "1":
                workshop = getWorkshop(3, weekIndex);
                break;
              case "2":
                workshop = getWorkshop(1, weekIndex);
                break;
              case "3":
                workshop = getWorkshop(2, weekIndex);
                break;
            }
        } else {
            switch (userLevel) {
              case "1":
                workshop = getWorkshop(2, weekIndex);
                break;
              case "2":
                workshop = getWorkshop(3, weekIndex);
                break;
              case "3":
                workshop = getWorkshop(1, weekIndex);
                break;
            }
        }
        return workshop;
      },
      printPDF() {
        try {
          this.downloading = true;
          const id = this.$route.params.id;
          const url = `${PUBLIC_API_URL}/youths/${id}/pdf`;
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        } catch (error) {
          this.$swal({
            title: "Ocurrio un error!",
            text: error,
            icon: "error"
          });
          this.downloading = false;
        }
      },
      formatDate(date){
        let localTime = moment.utc(date).toDate();
        return moment(localTime).format('LLLL');
      }
    }
  };
  </script>
  
  <style scoped>
    .header, .footer {
      margin-bottom: 20px;
    }
    .label {
      font-weight: bold;
    }
    .value {
      margin-bottom: 5px;
    }
  </style>
  