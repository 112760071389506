import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Juvenil from "@/views/Juvenil.vue";
import Infantil from "@/views/Infantil.vue";
import Trabajadores from "@/views/Workers.vue";
import JuvenilForm from '@/views/Forms/Juvenil';
import InfantilForm from '@/views/Forms/Infantil';
import Worker from '@/views/Forms/Worker';
import EntranceYouth from '@/views/Entrances/Youth';
import EntranceInfant from '@/views/Entrances/Infant';
import AsistenciasJuvenil from '@/views/Entrances/Table/Youth';
import AsistenciasInfantil from '@/views/Entrances/Table/Infant';
import RutasInfantil from '@/views/Routes/Infant';
import SedesInfantil from '@/views/Campuses/Infant';

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/trabajadores",
    name: "Trabajadores",
    component: Trabajadores
  },
  {
    path: "/trabajadores/:id",
    name: "Trabajador Detalle",
    component: Worker,
  },
  {
    path: "/recreativo-juvenil",
    name: "Juvenil",
    component: Juvenil,
  },
  {
    path: "/recreativo-juvenil/:id",
    name: "Juvenil Detalle",
    component: JuvenilForm,
  },
  {
    path: "/recreativo-infantil",
    name: "Infantil",
    component: Infantil
  },
  {
    path: "/recreativo-infantil/:id",
    name: "Infantil Detalle",
    component: InfantilForm,
  },
  {
    path: "/ingreso-infantil/:id",
    name: "Ingreso Infantil",
    component: EntranceInfant,
  },
  {
    path: "/ingreso-juvenil/:id",
    name: "Ingreso Juvenil",
    component: EntranceYouth,
  },
  {
    path: "/asistencias-juvenil",
    name: "Asistencias Juvenil",
    component: AsistenciasJuvenil
  },
  {
    path: "/asistencias-infantil",
    name: "Asistencias Infantil",
    component: AsistenciasInfantil
  },
  {
    path: "/rutas-infantil",
    name: "Rutas Infantil",
    component: RutasInfantil
  },
  {
    path: "/sedes-infantil",
    name: "Sedes Infantil",
    component: SedesInfantil
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
