<template>
    <div>
        <template v-if="isLoading">
            <div class="d-flex justify-content-center w-100 p-5 text-info font-weight-bold text-lg">
                Cargando datos ...
            </div>
        </template>
        <template v-else>
            <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                    <div class="row">
                    <div class="col-12 d-flex justify-content-between border-bottom">
                        <h5 class="mb-0 text-success text-2xl">Rutas Infantiles</h5>
                    </div>
                    </div>
                </div>
                <div class="card-body p-3 pb-0 mb-0">
                    <ul class="list-group">
                    <li
                        v-for="(route, index) in routes"
                        :key="index"
                        class="list-group-item border-0 ps-0 border-radius-lg"
                    >
                        <div
                        class=" d-flex  justify-content-between"
                        >
                        <h6 class="text-dark font-weight-bold text-sm">
                            {{ route.name }}
                        </h6>
                        <div class="d-flex align-items-center text-sm">
                            <h6 class="text-sm">{{ route.members_count }} Participantes</h6>
                            <button 
                            @click.stop="printPDF(route)"
                            class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                            <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                            Listado
                            </button>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
    
</template>

<script>
import axios from 'axios';
const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
export default {
    name: "routes-infant-list",
    data() {
        return {
            isLoading: false,
            routes: []
        }
    },
    mounted() {
        this.fetchRoutes();
    },
    methods:{
        async fetchRoutes(){
            this.isLoading = true;
            try {
                const response = await axios.get(`${PUBLIC_API_URL}/routes/count`);
                this.routes = response.data;
            } catch (error) {
                this.isLoading = false;
            }
            this.isLoading = false;
        },
        printPDF(route) {
            this.downloading = true;
            const id = route.id;
            const name = route.name;
            const url = `${PUBLIC_API_URL}/route/${id}/participants/infant?route_name=${name}`;
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            this.downloading = false;
        },
    }
}
</script>