<template>
    <div class="card mt-5 mb-4">
        <template v-if="isLoading">
          <div class="d-flex justify-content-center w-100 ">
            <p class="text-info font-weight-bold fs-3">Cargando datos...</p>
          </div>
          
        </template>
        <template v-else>
            <div class="card-header pb-0 d-flex justify-content-between align-items-center ">
                <div class="col-4">
                    <h4 class="text-success">Asistencia del Recreativo Infantil</h4>
                </div>
                <div class="col-5 d-flex">
                    <DatePicker @change-date="handlerStartDate" />
                    <button
                        class="px-3 mb-2 btn bg-gradient-success"
                        @click.stop="dateChange">
                        Cambiar Fechas
                    </button>
                    <button
                        @click.stop="printPDF"
                        class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                        <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                        Listado
                    </button>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                        <thead>
                            <tr>
                            <th class="text-uppercase text-primary text-xs font-weight-bolder opacity-7">Nombre</th>
                            <th class="text-uppercase text-primary text-xs font-weight-bolder opacity-7">Hora</th>
                            </tr>
                        </thead>
                        <template v-if="entrances.length < 1">
                            <div class="d-flex justify-content-center w-100 p-5 text-danger font-weight-bold text-lg">
                                No Hay Resultado de asistencias para el dia seleccionado
                            </div>
                        </template>
                        <template v-else>
                            <tbody class="p-5">
                                <tr v-for="(entrance, index) in entrances" :key="index">
                                <td>
                                    <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                        <a :href="`/recreativo-infantil/${entrance.user.id}`" target="_blank">
                                        <h6 class="mb-0 text-sm">{{ entrance.user.first_name }} {{ entrance.user.last_name }}</h6>
                                        </a>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <p class="text-xs text-primary font-weight-bold mb-0">{{ formatDate(entrance.created_at) }}</p>
                                </td>
                                </tr>
                            </tbody>
                        </template>
                        
                    </table>
                </div>
                <div class="d-flex justify-content-end align-items-center px-4 py-3">
                    <div class="d-flex align-items-center">
                    <label class="me-2">Mostrar:</label>
                    <select v-model="perPage" @change="fetchEntrances" class="form-select me-3">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    </div>
                    <button @click="changePage('first')" :disabled="!entrances.prev_page_url" class="btn btn-primary btn-sm me-2">Primera</button>
                    <button @click="changePage('prev')" :disabled="!entrances.prev_page_url" class="btn btn-primary btn-sm me-2">Anterior</button>
                    <span>Página {{ entrances.current_page }} de {{ entrances.last_page }}</span>
                    <button @click="changePage('next')" :disabled="!entrances.next_page_url" class="btn btn-primary btn-sm me-2">Siguiente</button>
                    <button @click="changePage('last')" :disabled="entrances.current_page === entrances.last_page" class="btn btn-primary btn-sm">Última</button>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
    import axios from "axios";
    const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
    import moment from "moment"
    import es from "moment/dist/locale/es";
    moment.updateLocale("es", es);
    import DatePicker from "../../../components/DatePicker.vue";
    export default {
        components: {DatePicker},
        data() {
            return {
                isLoading: false,
                entrances: { data: [] },
                search: '',
                currentPage: 1,
                perPage: 50,
                user_type: 'Infant',
                date: { start: new Date(), end: new Date() },
            };
        },
        created() {
            this.fetchEntrances();
        },
        methods:{
            handlerStartDate(date){
                this.date = date;
            },
            async fetchEntrances() {
                try {
                    this.isLoading = true
                    const params = {
                        page: this.currentPage,
                        per_page: this.perPage,
                        user_type: this.user_type,
                        start_date: this.formatDateForApi(this.date.start),
                        end_date: this.formatDateForApi(this.date.end),
                    };
                    const response = await axios.get(`${PUBLIC_API_URL}/entrances`, { params })
                    this.entrances = response.data;
                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    console.error("There was an error fetching the Entrances!", error);
                }  
            },
            changePage(direction) {
                if (direction === 'next' && this.youths.next_page_url) {
                    this.currentPage++;
                } else if (direction === 'prev' && this.youths.prev_page_url) {
                    this.currentPage--;
                } else if (direction === 'first') {
                    this.currentPage = 1;
                } else if (direction === 'last') {
                    this.currentPage = this.youths.last_page;
                }
                this.fetchEntrances();
            },
            formatDate(date){
                let localTime = moment.utc(date).toDate();
                return moment(localTime).format('LLLL');
            },
            dateChange(){
                console.log('*************** Change Date: ', this.date)
                this.fetchEntrances();
            },
            formatDateForApi(date) {
                // Formatear fecha para el API (ejemplo de formato)
                return moment(date).format('YYYY-MM-DD');
            },
            printPDF() {
                this.downloading = true;
                const params = {
                    user_type: 'Youth',
                    start_date: this.formatDateForApi(this.date.start),
                    end_date: this.formatDateForApi(this.date.end),
                };
                const url = `${PUBLIC_API_URL}/entrances/participants/infants?start_date=${params.start_date}&end_date=${params.end_date}&user_type=${params.user_type}`;
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                this.downloading = false;
            },
        }
    }
</script>