<template>
    <div>
        <p class="text-primary fs-1">{{ message }}</p>
    </div>
</template>

<script>
import axios from 'axios';
const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
export default {
    data() {
        return {
            type: 'Infant',
            message: ''
            
        }
    },
    mounted() {
        this.sendEntrance()
    },
    methods: {
        async sendEntrance(){
            try {
                const params = {
                    user_type: this.type,
                    user_id: this.$route.params.id,

                };
                const response = await axios.post(`${PUBLIC_API_URL}/entrances`,params);
                console.log('Response: ',response)
                this.message = response.data.message;
                const text = response.data.message;
                const title = response.data.canEnter ? 'Bienvenido!!' : 'No puede Ingresar';
                const icon = response.data.canEnter? 'success' : 'error'
                this.$swal({
                    title: title,
                    text: text,
                    icon: icon
                });
            } catch (error) {
                console.log('error:',error)
                this.$swal({
                    title: "Ocurrio un error!",
                    text: "No pudimos obtener los datos del juvenil!",
                    icon: "error"
                });
            }
        }
    },    
}

</script>