<template>
    <div class="card mb-4">
      <div class="row d-flex justify-content-end items-center">
          <div class="col-3">
            <label class="btn btn-info font-weight-bold">
                Importar Juveniles
                <input type="file" ref="file" name="file" @change="onChange" style="display: none;">
            </label>
          </div>
        <div class="d-flex justify-content-end col-xl-12 mb-xl-0 mb-4">
            <mini-statistics-card
            title="Total de inscritos"
            :value="`${youths.total}`"

            :icon="{
                component: 'fa fa-users',
                background: 'bg-info',
            }"
            />
        </div>
      </div>
      <div class="card-header pb-0 d-flex justify-content-between align-items-center">
        <h4 class="text-info">Participantes Recreativo Juvenil</h4>
        <input v-model="search" @input="searchYouths" type="text" class="form-control w-25" placeholder="Buscar por nombre"/>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <template v-if="isLoading">
          <div class="d-flex justify-content-center w-100 ">
            <p class="text-info font-weight-bold fs-3">Importado datos...</p>
          </div>
          
        </template>
        <template v-else>
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Edad</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Equipo</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nivel</th>
                  <th class="text-secondary opacity-7"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="youth in youths.data" :key="youth.id">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <a :href="`/recreativo-juvenil/${youth.id}`">
                          <h6 class="mb-0 text-sm">{{ youth.first_name }} {{ youth.last_name }}</h6>
                        </a>
                        <p class="text-xs text-secondary mb-0">{{ youth.email }}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ youth.age }}</p>
                  </td>
                  <td class="align-middle text-center">
                    <p class="text-xs font-weight-bold mb-0">{{ youth.teams[0]?.name || 'No asignado' }}</p>
                  </td>
                  <td class="align-middle text-center">
                    <p class="text-xs font-weight-bold mb-0">{{ youth.teams[0]?.level || 'No asignado' }}</p>
                  </td>
                  <td class="align-middle">
                    <a :href="`/recreativo-juvenil/${youth.id}`" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Editar Juvenil">Editar</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end align-items-center px-4 py-3">
            <div class="d-flex align-items-center">
              <label class="me-2">Mostrar:</label>
              <select v-model="perPage" @change="fetchYouths" class="form-select me-3">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <button @click="changePage('first')" :disabled="!youths.prev_page_url" class="btn btn-primary btn-sm me-2">Primera</button>
            <button @click="changePage('prev')" :disabled="!youths.prev_page_url" class="btn btn-primary btn-sm me-2">Anterior</button>
            <span>Página {{ youths.current_page }} de {{ youths.last_page }}</span>
            <button @click="changePage('next')" :disabled="!youths.next_page_url" class="btn btn-primary btn-sm me-2">Siguiente</button>
            <button @click="changePage('last')" :disabled="youths.current_page === youths.last_page" class="btn btn-primary btn-sm">Última</button>
          </div>
        </template>
        
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
  const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
  export default {
    name: "juvenil-table",
    components: {
        MiniStatisticsCard,
    },
    data() {
      return {
        isLoading: false,
        youths: { data: [] },
        search: '',
        currentPage: 1,
        perPage: 20,
      };
    },
    created() {
      this.fetchYouths();
    },
    methods: {
      fetchYouths() {
        const params = {
          page: this.currentPage,
          per_page: this.perPage,
          name: this.search
        };
        axios.get(`${PUBLIC_API_URL}/youths`, { params })
          .then(response => {
            this.youths = response.data;
          })
          .catch(error => {
            console.error("There was an error fetching the youths!", error);
          });
      },
      changePage(direction) {
        if (direction === 'next' && this.youths.next_page_url) {
          this.currentPage++;
        } else if (direction === 'prev' && this.youths.prev_page_url) {
          this.currentPage--;
        } else if (direction === 'first') {
          this.currentPage = 1;
        } else if (direction === 'last') {
          this.currentPage = this.youths.last_page;
        }
        this.fetchYouths();
      },
      searchYouths() {
        this.currentPage = 1;
        this.fetchYouths();
      },
      async importFile(){
        this.isLoading = true;
        try {
          const headers = {
            'Content-type': 'multipart/form-data'
          }
          let formData= new FormData();
          formData.append('file', this.file);
          const response = axios.post(`${PUBLIC_API_URL}/import-youth`, formData, headers).then( result =>{
            console.log(result);
            this.$swal({
              title: "Importado con Exito!",
              text: "Los datos del archivo fueron importados!",
              icon: "success"
            });
            this.fetchYouths();
            this.isLoading = false;
          }).catch(error => {
            console.log(error);
            this.$swal({
              title: "Ocurrio un error!",
              text: "No pudimos importar el archivo!",
              icon: "error"
            });
            this.isLoading = false;
          });
          console.log(response);
        } catch (error) {
          this.isLoading = false;
          console.log('error: ',error)
        }
        
      },
      async onChange(event) {
        this.file = event.target.files[0];
        if(this.file){
          this.importFile()
        }
        
      },
    }
  };
  </script>
  
  <style scoped>
  .table-responsive {
    overflow-x: auto;
  }
  </style>
  