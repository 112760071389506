<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <juvenil-table />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import JuvenilTable from "./components/JuvenilTable";
  
  export default {
    name: "Juvenil",
    components: {
      JuvenilTable,
    },
  };
  </script>
  