<template>
  <div class="card mb-4">
    <div class="d-flex justify-content-end col-xl-12 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Total de trabajadores"
          :value="`${customers.total}`"

          :icon="{
            component: 'fa fa-users',
            background: iconBackground,
          }"
        />
      </div>
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>Trabajadores del Recreativo</h6>
      <input v-model="search" @input="searchCustomers" type="text" class="form-control w-25" placeholder="Buscar por nombre"/>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Teléfono</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Hijos</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers.data" :key="customer.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ customer.first_name }} {{ customer.last_name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ customer.phone }}</p>
              </td>
              <td class="align-middle text-center">
                <p class="text-xs font-weight-bold mb-0">{{ customer.email }}</p>
              </td>
              <td class="align-middle text-center">
                <p class="text-xs font-weight-bold mb-0">{{ customer.youths.length + customer.infants.length }}</p>
              </td>
              <td class="align-middle">
                <a :href="`/trabajadores/${customer.id}`" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">Edit</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end align-items-center px-4 py-3">
        <div class="d-flex align-items-center">
          <label class="me-2">Mostrar:</label>
          <select v-model="perPage" @change="fetchCustomers" class="form-select me-3">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <button @click="changePage('first')" :disabled="!customers.prev_page_url" class="btn btn-primary btn-sm me-2">Primera</button>
        <button @click="changePage('prev')" :disabled="!customers.prev_page_url" class="btn btn-primary btn-sm me-2">Anterior</button>
        <span>Página {{ customers.current_page }} de {{ customers.last_page }}</span>
        <button @click="changePage('next')" :disabled="!customers.next_page_url" class="btn btn-primary btn-sm me-2">Siguiente</button>
        <button @click="changePage('last')" :disabled="customers.current_page === customers.last_page" class="btn btn-primary btn-sm">Última</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
export default {
  name: "trabajadores-table",
  components: {
    MiniStatisticsCard,
  },
  data() {
    return {
      iconBackground: "bg-gradient-dark",
      customers: { data: [] },
      search: '',
      currentPage: 1,
      perPage: 20,
    };
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    fetchCustomers() {
      const params = {
        page: this.currentPage,
        per_page: this.perPage,
        name: this.search
      };
      axios.get(`${PUBLIC_API_URL}/customers`, { params })
        .then(response => {
          this.customers = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the customers!", error);
        });
    },
    changePage(direction) {
      if (direction === 'next' && this.customers.next_page_url) {
        this.currentPage++;
      } else if (direction === 'prev' && this.customers.prev_page_url) {
        this.currentPage--;
      } else if (direction === 'first') {
        this.currentPage = 1;
      } else if (direction === 'last') {
        this.currentPage = this.customers.last_page;
      }
      this.fetchCustomers();
    },
    searchCustomers() {
      this.currentPage = 1;
      this.fetchCustomers();
    }
  }
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
</style>
