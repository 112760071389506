<template>
    <div class="container mt-5">
      <h3 class="text-success">Detalles del Infante</h3>
      <template v-if="!isLoading">
        <div class="row d-flex">
          
          <div class="col-8">
            <div class="row">
                <div class="col-1 text-primary">Trabajador:</div>
                <div class="col-8 mx-4">
                  <a :href="`/trabajadores/${infant.customer.id}`">
                    {{ infant.customer?.first_name }} {{ infant.customer?.last_name  }}
                  </a>
                </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row d-flex">
              <template v-if="readOnly">
                <div class="col-6">
                  <button @click="readOnly = false" class="btn btn-info">Editar Registro</button>
                </div>
                <div class="col-6">
                  <button class="btn btn-primary" @click="printPDF">
                    <template v-if="downloading">
                      Imprimiendo ...
                    </template>
                    <template v-else>
                      Imprimir Kardex
                    </template>
                    
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="col-6">
                  <button @click="readOnly = true" class="btn btn-secondary">Descartar</button>
                </div>
                <div class="col-6">
                  <button @click.stop="update" class="btn btn-success">Guardar</button>
                </div>
              </template>
            </div>
          </div>
        
        </div>
        <div class="row d-flex">
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Nombre</label>
              <input type="text" class="form-control" v-model="infant.first_name" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Apellido</label>
              <input type="text" class="form-control" v-model="infant.last_name" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Edad</label>
              <input type="text" class="form-control" v-model="infant.age" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Alergias</label>
              <input type="text" class="form-control" v-model="infant.allergy" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Equipo</label>
              <select v-if="!readOnly" class="form-control" v-model="selectedTeam">
                <option v-for="(option, index) in teams" :value="option" :key="index"><p>{{ option.name }}</p></option>
              </select>
              <input  v-if="readOnly && selectedTeam" type="text" class="form-control" v-model="selectedTeam.name" :disabled="true">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Nivel</label>
              <input type="text" class="form-control" v-model="selectedTeam.level" :disabled="true">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Responsable Nombre</label>
              <input type="text" class="form-control" v-model="infant.person_name" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Responsable Telefono</label>
              <input type="text" class="form-control" v-model="infant.person_phone" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Responsable Parentesco</label>
              <input type="text" class="form-control" v-model="infant.person_relationship" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Sede</label>
              <select v-if="!readOnly" class="form-control" v-model="selectedCampus">
                <option v-for="(campus, index) in campuses" :value="campus" :key="index"><p>{{ campus?.name }}</p></option>
              </select>
              <input  v-if="readOnly && selectedCampus" type="text" class="form-control" v-model="selectedCampus.name" :disabled="true">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Ruta</label>
              <select v-if="!readOnly" class="form-control" v-model="selectedRoute">
                <option v-for="(route, index) in routes" :value="route" :key="index"><p>{{ route.name }}</p></option>
              </select>
              <input  v-if="readOnly && selectedRoute" type="text" class="form-control" v-model="selectedRoute.name" :disabled="true">
            </div>
          </div>
          <h6 class="text-primary">Ingresos</h6>
          <table class="table table-bordered schedule">
            <thead>
              <tr>
                <th>Dia y hora</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entrance,index) in infant.entrances" :key="index">
                <td>{{ formatDate(entrance.created_at) }}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <template v-else>
        <p>Cargando datos...</p>
      </template>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
  import moment from "moment"
  import es from "moment/dist/locale/es";
  moment.updateLocale("es", es);
  export default {
    name: 'InfantDetails',
    data() {
      return {
        infant: null,
        isLoading: false,
        readOnly: true,
        teams: [],
        campuses: [],
        routes: [],
        selectedTeam: null,
        selectedCampus: null,
        selectedRoute: null
      };
    },
    created() {
      this.fetchInfantDetails();
    },
    methods: {
      async fetchInfantDetails() {
        this.isLoading = true;
        const id = this.$route.params.id;
    
        try {
          const response = await axios.get(`${PUBLIC_API_URL}/infants/${id}`);
          console.log('response: ',response.data)
          const responseRecord = await axios.get(`${PUBLIC_API_URL}/records/infants`);
          this.infant = response.data;
          this.teams = responseRecord.data.teams;
          this.routes = responseRecord.data.routes;
          this.campuses = responseRecord.data.campuses;
          if(this.infant?.teams[0]?.id){
            const payloadTeam = { 
              ...this.infant.teams[0],
              "pivot": {
                "youth_id": this.infant.id,
                "team_id": this.infant.teams[0].id
              }
            }
            this.selectedTeam = payloadTeam;
          }
          if(this.infant?.campuses[0]?.id){
            const payloadCampus = { 
              ...this.infant.campuses[0],
              "pivot": {
                "youth_id": this.infant.id,
                "team_id": this.infant.campuses[0].id
              }
            }
            this.selectedCampus = payloadCampus;
          }

          if(this.infant?.routes[0]?.id){
            const payloadRoute = { 
              ...this.infant.routes[0],
              "pivot": {
                "youth_id": this.infant.id,
                "team_id": this.infant.routes[0].id
              }
            }
            this.selectedRoute = payloadRoute;
          }
            
        } catch (error) {
          this.$swal({
            title: "Ocurrio un error!",
            text: "No pudimos obtener los datos del infantil!",
            icon: "error"
          });
        }
        
        this.isLoading = false;
      },
      async update(){
        
        this.isLoading = true;
        try {
          const payload = {
            ...this.infant,
            'team_id': this.selectedTeam?.id ?? null,
            'route_id': this.selectedRoute?.id ?? null,
            'campus_id': this.selectedCampus?.id ?? null
          }
          const response = await axios.put(`${PUBLIC_API_URL}/infants/${this.infant.id}`, payload);
          console.log('response: ',response.data)
          this.fetchInfantDetails()
          this.readOnly = true
          this.$swal({
            title: "Actualizado con Exito!",
            text: "Los datos del infantil fueron actualizados!",
            icon: "success"
          });
        } catch (error) {
          console.log('*********** Error: ', error)
          this.$swal({
            title: "Ocurrio un error!",
            text: "No pudimos actualizar los datos del infantil!",
            icon: "error"
          });
        }
      },
      printPDF() {
        try {
          this.downloading = true;
          const id = this.$route.params.id;
          const url = `${PUBLIC_API_URL}/infants/${id}/pdf`;
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        } catch (error) {
          this.$swal({
            title: "Ocurrio un error!",
            text: error,
            icon: "error"
          });
          this.downloading = false;
        }
      },
      formatDate(date){
        let localTime = moment.utc(date).toDate();
        return moment(localTime).format('LLLL');
      }
    }
  };
  </script>
  
  <style scoped>

  </style>
  