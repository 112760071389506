<template>
  <div class="datepicker">
    <!-- Input que muestra el rango de fechas seleccionado -->
    <input 
      type="text" 
      class="form-control" 
      :value="dateRangeText" 
      @focus="showDatePicker"
    >

    <!-- Pop-up del datepicker -->
    <div v-if="showPopup" class="datepicker-popup">
      <!-- Navegación entre meses y años -->
      <div class="datepicker-navigation">
        <button @click="prevMonth">&lt;</button>
        <span>{{ currentMonthYear }}</span>
        <button @click="nextMonth">&gt;</button>
      </div>

      <!-- Vista del calendario -->
      <div class="datepicker-calendar">
        <div class="datepicker-days">
          <div v-for="(day, index) in daysInMonth" :key="index" :class="dateClass(day)" @click="selectDate(day)">
            {{ day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      showPopup: false,
      currentDate: new Date()
    };
  },
  emits: ["input"],
  computed: {
    currentMonthYear() {
      return this.currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
    },
    daysInMonth() {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const days = new Date(year, month + 1, 0).getDate();
      return Array.from({ length: days }, (_, i) => i + 1);
    },
    dateRangeText() {
      let startText = this.startDate ? this.formatDate(this.startDate) : 'Inicio';
      let endText = this.endDate ? this.formatDate(this.endDate) : 'Termino';
      return `${startText} - ${endText}`;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.start && newValue.end) {
          this.startDate = new Date(newValue.start);
          this.endDate = new Date(newValue.end);
        } else {
          this.startDate = null;
          this.endDate = null;
        }
      }
    },
    startDate(newDate) {
      this.$emit('change-date', { start: newDate, end: this.endDate });
    },
    endDate(newDate) {
      this.$emit('change-date', { start: this.startDate, end: newDate });
    }
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      const day = d.getDate().toString().padStart(2, '0');
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    showDatePicker() {
      this.showPopup = true;
    },
    prevMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    },
    selectDate(day) {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const selectedDate = new Date(year, month, day);
      
      if (!this.startDate || selectedDate < this.startDate) {
        this.startDate = selectedDate;
        this.endDate = null;
      } else if (!this.endDate || selectedDate > this.endDate) {
        this.endDate = selectedDate;
        this.showPopup = false;
      } else {
        this.startDate = selectedDate;
        this.endDate = null;
      }
    },
    dateClass(day) {
      if (!this.startDate && !this.endDate) {
        return '';
      } else if (this.startDate && this.isSameDate(day, this.startDate)) {
        return 'selected-start';
      } else if (this.endDate && this.isSameDate(day, this.endDate)) {
        return 'selected-end';
      } else if (this.startDate && this.endDate && this.isBetween(day, this.startDate, this.endDate)) {
        return 'in-range';
      } else {
        return '';
      }
    },
    isSameDate(day, date) {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      return new Date(year, month, day).getTime() === new Date(date).getTime();
    },
    isBetween(day, startDate, endDate) {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const currentDate = new Date(year, month, day);
      return currentDate > new Date(startDate) && currentDate < new Date(endDate);
    }
  }
};
</script>

<style scoped>
.datepicker {
  position: relative;
}

.datepicker-popup {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

.datepicker-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.datepicker-calendar {
  display: flex;
  flex-direction: column;
}

.datepicker-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.datepicker-day {
  cursor: pointer;
  padding: 5px;
  text-align: center;
}

.selected-start {
  background-color: #007bff;
  color: #fff;
}

.selected-end {
  background-color: #dc3545;
  color: #fff;
}

.in-range {
  background-color: #cce5ff;
}

.form-control {
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
</style>
