<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <worker-table />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import WorkerTable from "./components/WorkerTable";
  
  export default {
    name: "Trabajadores",
    components: {
        WorkerTable,
    },
  };
  </script>
  