<template>
    <div class="container mt-5">
      <h3>Detalles del Trabajador</h3>
      <template v-if="!isLoading">
        <div class="row d-flex justify-content-end">
          <template v-if="readOnly">
            <div class="col-2">
              <button @click="readOnly = false" class="btn btn-info">Editar Registro</button>
            </div>
          </template>
          <template v-else>
            <div class="col-2">
              <button @click="readOnly = true" class="btn btn-secondary">Descartar</button>
            </div>
            <div class="col-2">
              <button @click.stop="update" class="btn btn-success">Guardar</button>
            </div>
          </template>
        </div>
        <div class="row d-flex">
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Nombre</label>
              <input type="text" class="form-control" v-model="customer.first_name" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Apellido</label>
              <input type="text" class="form-control" v-model="customer.last_name" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Email</label>
              <input type="text" class="form-control" v-model="customer.email" :disabled="readOnly">
            </div>
          </div>

          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Telefono</label>
              <input type="text" class="form-control" v-model="customer.phone" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Dirección 1</label>
              <input type="text" class="form-control" v-model="customer.address_1" :disabled="readOnly">
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="mb-3">
              <label class="form-label text-primary">Dirección 2</label>
              <input type="text" class="form-control" v-model="customer.address_2" :disabled="readOnly">
            </div>
          </div>
          <h5>Hijos(a)</h5>
            
                <table class="table table-bordered schedule">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Edad</th>
                        <th>Equipo</th>
                        <th>Nivel</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="customer.youths.length > 0">
                        <tr 
                            v-for="(youth,index) in customer.youths"
                            :key="index"  
                        >
                            <td>
                                <a :href="`/recreativo-juvenil/${youth.id}`">
                                    {{ youth.first_name }} {{ youth.last_name  }}
                                </a>
                            </td>
                            <td>{{ youth.age }}</td>
                            <td>{{ youth.teams[0]?.name }}</td>
                            <td>{{ youth.teams[0]?.level }}</td>
                        </tr>
                    </template>
                    <template v-if="customer.infants.length > 0">
                        <tr 
                            v-for="(infant,index) in customer.infants"
                            :key="index"  
                        >
                            <td>
                                <a :href="`/recreativo-infantil/${infant.id}`">
                                    {{ infant.first_name }} {{ infant.last_name  }}
                                </a>
                                
                            </td>
                            <td>{{ infant.age }}</td>
                            <td>{{ infant.teams[0]?.name }}</td>
                            <td>{{ infant.teams[0]?.level }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
      </template>

      <template v-else>
        <p>Cargando datos...</p>
      </template>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
  export default {
    name: 'CustomerDetails',
    data() {
      return {
        customer: null,
        isLoading: false,
        readOnly: true,
      };
    },
    created() {
      this.fetchCustomerDetails();
    },
    methods: {
      async fetchCustomerDetails() {
        this.isLoading = true;
        const id = this.$route.params.id;
    
        try {
          const response = await axios.get(`${PUBLIC_API_URL}/customers/${id}`);
          console.log('response: ',response.data)
          this.customer = response.data;
            
        } catch (error) {
          console.error("Hubo un error al obtener los detalles del trabajador:", error);

        }
        
        this.isLoading = false;
      },
      async update(){
        
        this.isLoading = true;
        try {
          
          const response = await axios.put(`${PUBLIC_API_URL}/customers/${this.customer.id}`, this.customer);
          console.log('response: ',response.data)
          this.fetchCustomerDetails()
          this.readOnly = true
          this.$swal({
            title: "Actualizado con Exito!",
            text: "Los datos del trabajador fueron actualizados!",
            icon: "success"
          });
        } catch (error) {
          this.$swal({
            title: "Ocurrio un error!",
            text: "No pudimos actualizar los datos del trabajador!",
            icon: "error"
          });
        }
      },
 
    }
  };
  </script>
  
  <style scoped>

  </style>
  