<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse  navText="Dashboard" :to="{ name: 'Dashboard' }">
          <template #icon>
            <spaceship />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse  navText="Trabajadores" :to="{ name: 'Trabajadores' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse class="font-weight-bold" navText="Juvenil" :to="{ name: 'Juvenil' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse class="font-weight-bold" navText="Infantil" :to="{ name: 'Infantil' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-primary text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Asistencia
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse class="font-weight-bold" navText="Asistencias Infaltil" :to="{ name: 'Asistencias Infantil' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse class="font-weight-bold" navText="Asistencias Juvenil" :to="{ name: 'Asistencias Juvenil' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-primary text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Rutas Y Sedes
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse class="font-weight-bold" navText="Sedes Infantil" :to="{ name: 'Sedes Infantil' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse class="font-weight-bold" navText="Rutas Infantil" :to="{ name: 'Rutas Infantil' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>

</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import Document from "../../components/Icon/Document.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Educación Recreativa",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    CustomerSupport,
    Spaceship,
    Document 

  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
