<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <template v-if="!isLoading">
        <div class="row">
          <div class="d-flex justify-content-between">
            <div class="col-2">
              <label class="me-2 text-primary text-lg"> Elegir Semana:</label>
              <select v-model="currentWeek" @change="fetchTotals" class="form-select me-3">
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
            <div class="d-flex col-">
              <mini-statistics-card
                title="Total Asistencia Hoy"
                :value="currentEntrances.total"
                :icon="{
                  component: 'fa fa-users',
                  background: iconBackground,
                }"
                direction-reverse
              />
              <mini-statistics-card
                title="Infantil Asistencia Hoy"
                :value="currentEntrances.infants"
                :icon="{
                  component: 'fa fa-users',
                  background: 'bg-gradient-success',
                }"
                direction-reverse
              />
              <mini-statistics-card
                title="Juvenil Asistencia Hoy"
                :value="currentEntrances.youths"
                :icon="{
                  component: 'fa fa-users',
                  background: 'bg-gradient-info',
                }"
                direction-reverse
              />
            </div>
          </div>
          
          <h4 class="mt-4 text-primary">Talleres:</h4>
          <div class="row">
            <div class="col-6">
              <div class="card mt-4">
                <div class="card-header pb-0 p-3 ">
                  <div class="row border-bottom">
                    <div class="col-6 d-flex align-items-center ">
                      <h5 class="mb-0">Ciencias</h5>
                    </div>
                  </div>
                </div>
                <div class="card-body p-3 pb-0 mb-0">
                  <ul class="list-group">
                    <li
                      v-for="(scienceWorkshop, scienceIndex) in scienceWorkshops"
                      :key="scienceIndex"
                      class="list-group-item border-0 ps-0 mb-2 border-radius-lg"
                    >
                      <div  
                        class="d-flex flex-column"
                      >
                        <div>
                          <h6 class="text-primary border-bottom">{{ scienceWorkshop.name }}</h6>
                        </div>
                        <div class="d-flex flex-column">
                          <div
                            class=" d-flex  justify-content-between"
                          >
                            <h6 class="text-dark font-weight-bold text-sm">
                              Teens
                            </h6>
                            <div class="d-flex align-items-center text-sm">
                              <h6 class="text-sm">{{ scienceWorkshop.level_1_members_count }} Participantes</h6>
                              <button
                                @click.stop="printPDF(scienceWorkshop, 1)"
                                class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                                <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                                Listado
                              </button>
                            </div>
                          </div>
                          <div
                            class=" d-flex justify-content-between"
                          >
                            <h6 class="text-dark font-weight-bold text-sm">
                              Juniors
                            </h6>
                            <div class="d-flex align-items-center text-sm">
                              <h6 class="text-sm">{{ scienceWorkshop.level_2_members_count }} Participantes</h6>
                              <button
                                @click.stop="printPDF(scienceWorkshop, 2)" 
                                class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                                <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                                Listado
                              </button>
                            </div>
                          </div>
                          <div
                            class=" d-flex justify-content-between"
                          >
                            <h6 class="text-dark font-weight-bold text-sm">
                              Master
                            </h6>
                            <div class="d-flex align-items-center text-sm">
                              <h6 class="text-sm">{{ scienceWorkshop.level_3_members_count }} Participantes</h6>
                              <button 
                                @click.stop="printPDF(scienceWorkshop, 3)" 
                                class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                                <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                                Listado
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                  <div class="row border-bottom">
                    <div class="col-6 d-flex align-items-center">
                      <h5 class="mb-0">Arte y Humanidades</h5>
                    </div>
                  </div>
                </div>
                <div class="card-body p-3 pb-0 mb-0">
                  <ul class="list-group">
                    <li
                      v-for="(artWorkshop, artIndex) in artWorkshops"
                      :key="artIndex"
                      class="list-group-item border-0 ps-0 mb-2 border-radius-lg"
                    >
                      <div 
                        class="d-flex flex-column"
                      >
                        <div class="border-bottom">
                          <h6 class="text-primary">{{ artWorkshop.name }}</h6>
                        </div>
                        <div class="d-flex flex-column">
                          <div
                            class="d-flex justify-content-between"
                          >
                            <h6 class="text-dark font-weight-bold text-sm">
                              Teens
                            </h6>
                            <div class="d-flex align-items-center text-sm">
                              <h6 class="text-sm">{{ artWorkshop.level_1_members_count }} Participantes</h6>
                              <button 
                                @click.stop="printPDF(artWorkshop, 1)" 
                                class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                                <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                                Listado
                              </button>
                            </div>
                          </div>
                          <div
                            class=" d-flex justify-content-between"
                          >
                            <h6 class="text-dark font-weight-bold text-sm">
                              Juniors
                            </h6>
                            <div class="d-flex align-items-center text-sm">
                              <h6 class="text-sm">{{ artWorkshop.level_2_members_count }} Participantes</h6>
                              <button
                                @click.stop="printPDF(artWorkshop, 2)"  
                                class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                                <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                                Listado
                              </button>
                            </div>
                          </div>
                          <div
                            class=" d-flex justify-content-between"
                          >
                            <h6 class="text-dark font-weight-bold text-sm">
                              Master
                            </h6>
                            <div class="d-flex align-items-center text-sm">
                              <h6 class="text-sm">{{ artWorkshop.level_3_members_count }} Participantes</h6>
                              <button 
                                @click.stop="printPDF(artWorkshop, 3)" 
                                class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                                <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                                Listado
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <h4 class="mt-4 text-primary">Equipos:</h4>
          <div class="row">
            <div class="col-6">
              <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-between border-bottom">
                      <h5 class="mb-0 text-info text-2xl">Desiertos Juvenil</h5>
                      <h6 class="text-primary">{{ youthTeams1Count + youthTeams2Count + youthTeams3Count }} Participantes</h6> 
                    </div>
                  </div>
                </div>
                <div class="card-body p-3 pb-0 mb-0">
                  <div class="d-flex justify-content-between mt-2 border-bottom">
                    <h6 class="text-primary">Teens</h6>
                    <h6 class="text-primary">{{ youthTeams1Count }} Participantes</h6>
                  </div>
                  <ul class="list-group">
                    <li
                      v-for="(youthTeam, youthIndex) in youthTeams1"
                      :key="youthIndex"
                      class="list-group-item border-0 ps-0 border-radius-lg"
                    >
                      <div
                        class=" d-flex  justify-content-between"
                      >
                        <h6 class="text-dark font-weight-bold text-sm">
                          {{ youthTeam.name }}
                        </h6>
                        <div class="d-flex align-items-center text-sm">
                          <h6 class="text-sm">{{ youthTeam.members_count }} Participantes</h6>
                          <button
                            @click.stop="printPDFTeam(youthTeam,'youth')"
                            class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                            <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                            Listado
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="d-flex justify-content-between mt-2 border-bottom">
                    <h6 class="text-primary">Juniors</h6>
                    <h6 class="text-primary">{{ youthTeams2Count }} Participantes</h6>
                  </div>
                  <ul class="list-group">
                    <li
                      v-for="(youth2Team, youth2Index) in youthTeams2"
                      :key="youth2Index"
                      class="list-group-item border-0 ps-0 border-radius-lg"
                    >
                      <div
                        class=" d-flex  justify-content-between"
                      >
                        <h6 class="text-dark font-weight-bold text-sm">
                          {{ youth2Team.name }}
                        </h6>
                        <div class="d-flex align-items-center text-sm">
                          <h6 class="text-sm">{{ youth2Team.members_count }} Participantes</h6>
                          <button 
                            @click.stop="printPDFTeam(youth2Team,'youth')"
                            class="btn btn-link text-primary  text-sm mb-0 px-0 ms-4">
                            <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                            Listado
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="d-flex justify-content-between mt-2 border-bottom">
                    <h6 class="text-primary">Master</h6>
                    <h6 class="text-primary">{{ youthTeams3Count }} Participantes</h6>
                  </div>
                  
                  <ul class="list-group">
                    <li
                      v-for="(youth3Team, youth3Index) in youthTeams3"
                      :key="youth3Index"
                      class="list-group-item border-0 ps-0 border-radius-lg"
                    >
                      <div
                        class="d-flex justify-content-between"
                      >
                        
                        <h6 class="text-dark font-weight-bold text-sm">
                          {{ youth3Team.name }}
                        </h6>
                        <div class="d-flex align-items-center text-sm">
                          <h6 class="text-sm">{{ youth3Team.members_count }} Participantes</h6>
                          <button 
                            @click.stop="printPDFTeam(youth3Team,'youth')"
                            class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                            <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                            Listado
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-between border-bottom">
                      <h5 class="mb-0 text-success text-2xl">Infantil</h5>
                      <h6 class="text-primary">{{ infantTeamCount }} Participantes</h6> 
                    </div>
                  </div>
                </div>
                <div class="card-body p-3 pb-0 mb-0">
                  <ul class="list-group">
                    <li
                      v-for="(infantTeam, infantIndex) in infantTeams"
                      :key="infantIndex"
                      class="list-group-item border-0 ps-0 border-radius-lg"
                    >
                      <div
                        class=" d-flex  justify-content-between"
                      >
                        <h6 class="text-dark font-weight-bold text-sm">
                          {{ infantTeam.name }}
                        </h6>
                        <div class="d-flex align-items-center text-sm">
                          <h6 class="text-sm">{{ infantTeam.members_count }} Participantes</h6>
                          <button 
                            @click.stop="printPDFTeam(infantTeam,'infant')"
                            class="btn btn-link text-primary text-sm mb-0 px-0 ms-4">
                            <i class="fas fa-file-pdf text-sm me-1" aria-hidden="true"></i>
                            Listado
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <p>Cargando datos...</p>
      </template>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

import axios from 'axios';
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

const PUBLIC_API_URL = process.env.VUE_APP_PUBLIC_API_URL;
export default {
  name: "dashboard-default",
  components: {MiniStatisticsCard},
  data() {
    return {
      totals: null,
      teams: null,
      isLoading: false,
      currentWeek: 1,
      iconBackground: "bg-gradient-primary",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      scienceWorkshops: [],
      artWorkshops: [],
      infantTeams:[],
      infantTeamCount: 0,
      youthTeams: [],
      youthTeams1: [],
      youthTeams2: [],
      youthTeams3: [],
      youthTeams1Count: 0,
      youthTeams2Count: 0,
      youthTeams3Count: 0,
      currentEntrances: {}
    };
  },
  created() {
    this.fetchTotals();
  },
  methods: {
    async fetchTotals() {
      this.isLoading = true;
      try {
        const params = {
          week: this.currentWeek
        };
        const response = await axios.get(`${PUBLIC_API_URL}/workshops`,{ params });
        this.totals = response.data;
        this.scienceWorkshops = [];
        this.artWorkshops = [];
        this.infantTeams = [];
        this.youthTeams = [];
        this.youthTeams1 = [];
        this.youthTeams2 = [];
        this.youthTeams3 = [];
        this.youthTeams1Count = 0;
        this.youthTeams2Count = 0;
        this.youthTeams3Count = 0;
        this.infantTeamCount = 0;
        this.totals.forEach(element => {
          if(element.type == 'Ciencias'){
            this.scienceWorkshops.push(element)
          }else{
            this.artWorkshops.push(element)
          }
        });
        const responseTeams = await axios.get(`${PUBLIC_API_URL}/teams`);
        this.teams = responseTeams.data;

        const responseEntrances =  await axios.get(`${PUBLIC_API_URL}/records/entrances-count`);
        this.currentEntrances = responseEntrances.data
        this.teams.forEach(element => {
          if(element.type == 0){
            this.infantTeams.push(element)
            this.infantTeamCount = this.infantTeamCount + element.members_count

          }else{
            this.youthTeams.push(element)
          }
        });

        this.youthTeams.forEach(element => {
          if(element.level === '1'){
            this.youthTeams1.push(element)
            this.youthTeams1Count = this.youthTeams1Count + element.members_count
          }else if(element.level === '2'){
            this.youthTeams2.push(element)
            this.youthTeams2Count = this.youthTeams2Count + element.members_count
          }else{
            this.youthTeams3.push(element)
            this.youthTeams3Count = this.youthTeams3Count + element.members_count
          }
        });
      } catch (error) {
        console.error("Hubo un error al obtener los totales:", error);
      }
      this.isLoading = false;
    },
    printPDF(workshop, level) {
      this.downloading = true;
      const id = workshop.id;
      const week = this.currentWeek;
      const name = workshop.name;
      const url = `${PUBLIC_API_URL}/workshop/${id}/participants?week=${week}&user_level=${level}&workshop_name=${name}`;
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      this.downloading = false;
    },
    printPDFTeam(team, type) {
      this.downloading = true;
      const id = team.id;
      const name = team.name;
      const url = `${PUBLIC_API_URL}/team/${id}/participants/${type}?team_name=${name}`;
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      this.downloading = false;
    },
  },
 
};
</script>
